import { Service } from '@wix/bookings-uou-types';
import { Preset } from '../../types/types';
import { isCalendarPage, isCalendarWidget } from '../presets';

export const getAvailableServicesByPreset = ({
  availableServices,
  preset,
  isServiceListDropdownEnabled,
}: {
  availableServices: Service[];
  preset: Preset;
  isServiceListDropdownEnabled: boolean;
}) => {
  return isServiceListDropdownEnabled &&
    availableServices.length &&
    (isCalendarPage(preset) || isCalendarWidget(preset))
    ? [availableServices[0]]
    : availableServices;
};
