import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { CalendarState } from '../../controller';
import { MemoizedViewModalFactory } from '../viewModel';
import { Optional } from '../../../../types/types';
import { isCalendarPage } from '../../../../utils/presets';

export type BackButtonViewModel = {
  label: string;
};

export const memoizedBackButtonViewModel: MemoizedViewModalFactory<
  Optional<BackButtonViewModel>
> = {
  dependencies: {
    state: ['rescheduleBookingDetails'],
  },
  createViewModel: createBackButtonViewModel,
};

export function createBackButtonViewModel({
  state,
  context,
}: ViewModelFactoryParams<
  CalendarState,
  CalendarContext
>): Optional<BackButtonViewModel> {
  const { t, preset, hasReferralParam, wixSdkAdapter, experiments } = context;
  const { rescheduleBookingDetails } = state;

  const isCartSpecEnabled = experiments.enabled('specs.bookings.cart');

  if (
    (isCartSpecEnabled && wixSdkAdapter.isBackFromFormWithCart()) ||
    !isCalendarPage(preset) ||
    rescheduleBookingDetails ||
    !hasReferralParam
  ) {
    return;
  }

  return {
    label: t('app.calendar.back'),
  };
}
