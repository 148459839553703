import { createSettingsParams } from '@wix/tpa-settings';
import timetableSettingsParams from '../WeeklyTimetable/settingsParams';
import { LayoutOptions } from '../../types/types';
import { ISettingsParams } from '../BookingCalendar/settingsParams';

type DailyAgendaSettingsOverride = Pick<ISettingsParams, 'calendarLayout'>;

const dailyAgendaSettingsParams =
  createSettingsParams<DailyAgendaSettingsOverride>({
    calendarLayout: {
      getDefaultValue: () => LayoutOptions.DAILY_AGENDA_WEEKLY_PICKER,
    },
  });

export default {
  ...timetableSettingsParams,
  ...dailyAgendaSettingsParams,
};
