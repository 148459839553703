import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk';
import { IWixAPI } from '@wix/yoshi-flow-editor';
import { Preset } from '../../types/types';
import { isCalendarPage } from '../../utils/presets';

export const onLocationURLChange = ({
  wixCodeApi,
  wixSdkAdapter,
  callback,
  preset,
  isServiceListDropdownEnabled,
}: {
  wixCodeApi: IWixAPI;
  wixSdkAdapter: WixOOISDKAdapter;
  callback: Function;
  preset: Preset;
  isServiceListDropdownEnabled: boolean;
}) => {
  if (
    isServiceListDropdownEnabled &&
    isCalendarPage(preset) &&
    wixSdkAdapter.isBackFromFormWithCart()
  ) {
    wixCodeApi.location.onChange(() => callback());
  }
};
